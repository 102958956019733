export default {
  computed: {
    list() {
      return this.$store.state.moduleVirtuozzo.list;
    },
    isLoading() {
      return this.$store.state.moduleVirtuozzo.isLoading;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleVirtuozzo/fetchList');
    },
  },
};
